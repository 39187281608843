export const WEB_STORAGE_KEYS = {
    auth: 'auth',
    language: 'locale',
    installer: 'installer',
    order_id: 'order_id',
    installer_match: 'installer_match',
    site_id: 'current_site_id',
    installerCount: 'installer_count',
    enlighten_admin: 'enlighten_ISR',
    calculate: 'calculate',
    feedBack: 'feedBack',
    bomList: 'bomList',
    contactInfo: 'contactInfo'
};

export const REG_PATTERN = {
    phone: /^\d{10}$/,
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, /* a.b@abc.abc */
    usPhone: /^\d{10}$|^\d{3}-\d{3}-\d{4}$/,
    dePhone: /^((?:\s?(?:\()?\d(?:\))?){6,16})$/,
    auPhone: /^\d{9}$/,
    zip: /^\d{6}$|^\d{5}$|^\d{5}-\d{4}$/,
    name: /^([a-zA-Z]+ *)+$/,
    fullName: /^[a-zA-Z.-]+ +([a-zA-Z.-]+ *)+$/,
    amount: /^(\d{1,5})$/,
    year: /^(19|20)\d{2}$/,
    germanFullName: /^[a-zA-ZäöüÄÖÜß.-]+ +([a-zA-ZäöüÄÖÜß.-]+ *)+$/,
    indianZip: /^\d{6}$/,
    'es-es': {
        phone: /^\d{9}$/,
        name: /^([a-zA-ZáéíóúâêôãõçÁÉÍÓÚÂÊÔÃÕÇüñÜÑ]+ *)+$/,
    },
    germanzip: /^\d{6}$|^\d{5}$|^\d{4}$|^\d{5}-\d{4}$/
};

export const CONS_PROFILE_LIST = [
    { key: 'Family with kids', title: 'Family with kids', value: 0 },
    { key: '2-person household', title: '2-person household', value: 2 },
    { key: '1-person household', title: '1-person household', value: 1 },
    [{ key: 'Mornings (6 AM - 10 AM)', title: 'Mornings' },
    { key: 'Afternoons (10 AM - 4 PM)', title: 'Afternoons' },
    { key: 'Evenings (4 PM - 9 PM)', title: 'Evenings' },
    { key: 'Nights (9 PM - 6 AM)', title: 'Nights' }],
    { key: 'Household with school kids', title: 'Household with school kids', value: 4 },
    { key: 'Household with infants', title: 'Household with infants', value: 5 },
    { key: 'Single or two person household', title: 'Single or two person household', value: 6 },
    { key: 'Retirees or working from home couple', title: 'Retirees or working from home couple', value: 7 },
    { key: 'Nighttime focused', title: 'Nighttime focused', value: 8 },
];
export const MODE = { fullBackup: 0, energyIndependence: 1, savings: 2 };
export const BACKUP_MODES =
    [
        { key: 'Energy Independence', value: 1, title: 'Be more grid independent.', description: `Self-power more than 85% of your consumption.` },
        { key: 'Power Backup during Outages', value: 0, title: 'Get respite from grid outages.', description: `Backup your home during outages.` },
        { key: 'Savings on Monthly Electricity Bill', value: 2, title: 'Maximize your electricity bill savings.', description: `Maximize your savings and get backup for 6 hours during outages.` }
    ];
export const MICRO_LIST_VAL =
    [
        { label: 'IQ8+', value: 290, peak: 0.300, continuous_output_current:1.21, part_number: 'IQ8PLUS-72-2-US', max_micro_per_branch: 13 },
        { label: 'IQ8A', value: 349, peak: 0.366, continuous_output_current:1.45, part_number: 'IQ8A-72-2-US', max_micro_per_branch: 11 },
        { label: 'IQ8M', value: 325, peak: 0.330, continuous_output_current:1.35, part_number: 'IQ8M-72-2-US', max_micro_per_branch: 11 },
        { label: 'IQ8', value: 240, peak: 0.250, continuous_output_current:1, part_number: 'IQ8-60-2-US', max_micro_per_branch: 16 },
        { label: 'IQ7+', value: 290, peak: 0.295, part_number: 'IQ7PLUS-Q-12-20-1', max_micro_per_branch: 13 },
        { label: 'IQ7X', value: 315, peak: 0.320, part_number: 'IQ7X-Q-12-20-1', max_micro_per_branch: 12 },
        { label: 'IQ7A', value: 349, peak: 0.366, part_number: 'IQ7A-Q-12-20-1', max_micro_per_branch: 11 },
        { label: 'IQ7', value: 240, peak: 0.250, part_number: 'IQ7-Q-12-17-1', max_micro_per_branch: 16 },
        { label: 'IQ6+', value: 280, peak: 0.290, part_number: 'IQ6PLUS-72-2-US', max_micro_per_branch: 13 },
        { label: 'IQ6', value: 230, peak: 0.240, part_number: 'IQ6-60-2-US', max_micro_per_branch: 16 }
    ];
export const MICRO_LIST = MICRO_LIST_VAL.slice(0, -6);
export const NEW_USER_MICRO_LIST = MICRO_LIST_VAL.slice(0, -2);

// export const EXISTING_USER_MICRO_LIST =
//     [
//         { label: 'IQ8+', value: 290, peak: 0.300, part_number: 'IQ8PLUS-72-2-US', max_micro_per_branch: 11 },
//         { label: 'IQ8', value: 240, peak: 0.250, part_number: 'IQ8-60-2-US', max_micro_per_branch: 11 }
//     ];
export const APPLIANCE_LIMITS = {
    operatingPower: {
        Watts: { min: 1, max: 30000 },
        Ton: { min: 0.5, max: 30 },
        Amps: { min: 0.1, max: 125 },
        hp: { min: 0.5, max: 25 }
    },
    surgePower: {
        Watts: { min: 1, max: 45000 },
        Ton: { min: 0.5, max: 35 },
        Amps: { min: 0.1, max: 187 },
        hp: { min: 0.5, max: 40 },
        factor: { min: 1, max: 10 }
    },
    activeHours: { min: 0.1, max: 24 },
    qty: { min: 0, max: 100 },
    consumption: {
        Day: { min: 10, max: 150 },
        Month: { min: 300, max: 4500 },
        Year: { min: 4, max: 54 }
    }
};
export const APPLIANCE_LIMITS_DE = {
    operatingPower: {
        Watts: { min: 1, max: 30000 },
        Ton: { min: 0.5, max: 30 },
        Amps: { min: 0.1, max: 125 },
        hp: { min: 0.5, max: 25 }
    },
    surgePower: {
        Watts: { min: 1, max: 45000 },
        Ton: { min: 0.5, max: 35 },
        Amps: { min: 0.1, max: 187 },
        hp: { min: 0.5, max: 40 },
        factor: { min: 1, max: 10 }
    },
    activeHours: { min: 0.1, max: 24 },
    qty: { min: 0, max: 100 },
    consumption: {
        Day: { min: 1, max: 110 },
        Month: { min: 30, max: 3300 },
        Year: { min: 360, max: 40000 }
    }
};
export const APPLIANCE_DESCRIPTION = {
    'Large Appliances': 'High consumption, Regular-use',
    'Essential Appliances': 'Low consumption, Daily-use',
    'Heavy Appliances': 'Very high consumption, backup not recommended​​',
    'Additional Appliances': 'Appliances you added'
};
export const MICRO_UPGRADE_MAP = {
    M175: { new: 'IQ7-PD-72', replaceUnits: 1, sum: 0 },
    M190: { new: 'IQ7-PD-72', replaceUnits: 1, sum: 0 },
    M210: { new: 'IQ7-PD-84', replaceUnits: 1, sum: 0 },
    M215: { new: 'IQ7', replaceUnits: 1, sum: 0 },
    M250: { new: 'IQ7+', replaceUnits: 1, sum: 0 },
    D380: { new: 'IQ7-PD-72', replaceUnits: 2, sum: 0 },
    other: { new: 'IQ7', replaceUnits: 1, sum: 0 }
};
export const BILL_FACTORS =
    [
        { s: ['connecticut', 'ct'], f: .2895, bi: 1, ao: 156 },
        { s: ['maine', 'me'], f: .2913, bi: 1.9, ao: 143 },
        { s: ['massachusetts', 'ma'], f: .2802, bi: 1.7, ao: 156 },
        { s: ['new hampshire', 'nh'], f: .2576, bi: 1.4, ao: 116 },
        { s: ['rhode island', 'ri'], f: .3178, bi: 1.7, ao: 89 },
        { s: ['vermont', 'vt'], f: .2211, bi: 1.3, ao: 122 },
        { s: ['new jersey', 'nj'], f: .1751, bi: -.5, ao: 116 },
        { s: ['new york', 'ny'], f: .227, bi: -1.3, ao: 141 },
        { s: ['pennsylvania', 'pa'], f: .1843, bi: -1, ao: 135 },
        { s: ['illinois', 'il'], f: .1583, bi: .3, ao: 103 },
        { s: ['indiana', 'in'], f: .1517, bi: 2, ao: 106 },
        { s: ['michigan', 'mi'], f: .1906, bi: 1.4, ao: 194 },
        { s: ['ohio', 'oh'], f: .1601, bi: -.8, ao: 119 },
        { s: ['wisconsin', 'wi'], f: .1705, bi: -.1, ao: 66 },
        { s: ['iowa', 'ia'], f: .1337, bi: 1.7, ao: 49 },
        { s: ['kansas', 'ks'], f: .1372, bi: .2, ao: 64 },
        { s: ['minnesota', 'mn'], f: .1529, bi: 1.6, ao: 82 },
        { s: ['missouri', 'mo'], f: .1242, bi: .5, ao: 85 },
        { s: ['nebraska', 'ne'], f: .1176, bi: .4, ao: 66 },
        { s: ['north dakota', 'nd'], f: .1166, bi: .2, ao: 45 },
        { s: ['south dakota', 'sd'], f: .1299, bi: 1, ao: 69 },
        { s: ['delaware', 'de'], f: .1746, bi: -1.2, ao: 91 },
        { s: ['district of columbia', 'dc'], f: .1802, bi: .3, ao: 117 },
        { s: ['florida', 'fl'], f: .1736, bi: -.1, ao: 173 },
        { s: ['georgia', 'ga'], f: .1375, bi: .5, ao: 126 },
        { s: ['maryland', 'md'], f: .184, bi: -1.4, ao: 107 },
        { s: ['north carolina', 'nc'], f: .1475, bi: .2, ao: 174 },
        { s: ['south carolina', 'sc'], f: .1447, bi: .9, ao: 120 },
        { s: ['virginia', 'va'], f: .1427, bi: .4, ao: 129 },
        { s: ['west virginia', 'wv'], f: .1507, bi: 0.9, ao: 150 },
        { s: ['alabama', 'al'], f: .1502, bi: 1, ao: 110 },
        { s: ['kentucky', 'ky'], f: .1299, bi: 1.1, ao: 83 },
        { s: ['mississippi', 'ms'], f: .1367, bi: -.5, ao: 120 },
        { s: ['tennessee', 'tn'], f: .1257, bi: .8, ao: 61 },
        { s: ['arkansas', 'ar'], f: .1271, bi: .1, ao: 92 },
        { s: ['louisiana', 'la'], f: .1192, bi: .2, ao: 115 },
        { s: ['oklahoma', 'ok'], f: .1327, bi: -.1, ao: 69 },
        { s: ['texas', 'tx'], f: .1471, bi: -.2, ao: 128 },
        { s: ['arizona', 'az'], f: .1452, bi: .3, ao: 134 },
        { s: ['colorado', 'co'], f: .1454, bi: .5, ao: 81 },
        { s: ['idaho', 'id'], f: .1215, bi: -.5, ao: 46 },
        { s: ['montana', 'mt'], f: .1296, bi: .3, ao: 39 },
        { s: ['nevada', 'nv'], f: .1463, bi: -1.5, ao: 65 },
        { s: ['new mexico', 'nm'], f: .145, bi: -1.3, ao: 44 },
        { s: ['utah', 'ut'], f: .1122, bi: -.7, ao: 73 },
        { s: ['wyoming', 'wy'], f: .1247, bi: .3, ao: 32 },
        { s: ['california', 'ca'], f: .39, bi: 1.8, ao: 199 },
        { s: ['oregon', 'or'], f: .1324, bi: .1, ao: 99 },
        { s: ['washington', 'wa'], f: .1134, bi: 1.7, ao: 104 },
        { s: ['alaska', 'ak'], f: .2398, bi: 2.8, ao: 50 },
        { s: ['hawaii', 'hi'], f: .4269, bi: 1.9, ao: 119 }
    ];
export const LOAD_FACTOR_MAP = {
    'hp': [
        { load: 0.5, factor: 2.352 },
        { load: 0.75, factor: 2.208 },
        { load: 1, factor: 1.92 },
        { load: 1.5, factor: 1.6 },
        { load: 2, factor: 1.44 },
        { load: 3, factor: 1.36 },
        { load: 5, factor: 1.344 },
        { load: 15, factor: 1.28 }
    ],
    'ton': [
        { load: 1.5, factor: 1.52 },
        { load: 2, factor: 1.44 },
        { load: 3, factor: 1.16 },
        { load: 4, factor: 1.05 },
        { load: 5, factor: 1.152 },
        { load: 16, factor: 1.02 }
    ],
    defaults: {
        'hp': 1.28,
        'ton': 1.02,
        'amps': 0.24
    }
};
export const HOME_SIZE_CONS = [ /* consumption based on pure home size */
    { slab: 1, minSqft: 500, maxSqft: 1200, baseConsumption: 15, addOnConsPerSqft: 0.01428 },
    { slab: 2, minSqft: 1200, maxSqft: 3400, baseConsumption: 25, addOnConsPerSqft: 0.00454 },
    { slab: 3, minSqft: 3400, maxSqft: 6000, baseConsumption: 35, addOnConsPerSqft: 0.01115 }
];
